<template>
  <section class="head">
    <div class="title">
      {{ title }}
    </div>
  </section>
</template>

<script>
export default {
  name: "head",
  props: {
    title: {
      type: String,
      default: 'AIMS同步插件'
    }
  }
}
</script>

<style>
.head {
  height: 10vh;
  background: #42b983;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
  font-size: 24px !important;
  font-weight: 600;
}

</style>
