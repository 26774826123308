import request from './request'

export function list(data) {
    return request({
        url: '/officeaddin/memberstatus4',
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: '/officeaddin/update/member',
        method: 'post',
        data
    })
}

