import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Dialog from "../views/Dialog.vue";
import {getToken} from "@/utils/auth";
import Prevent from "@/views/Prevent";
import Editor from "@/views/Editor";
import Expired from "@/views/Expired";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    title: '登录'
  },
  {
    path: "/dialog/:type",
    name: "Dialog",
    component: Dialog,
    title: '选择'
  },
  {
    path: "/prevent",
    name: "Prevent",
    component: Prevent,
    title: '提醒'
  },
  {
    path: "/editor",
    name: "editor",
    component: Editor,
    title: '提醒'
  }, {
    path: "/expired",
    name: "expired",
    component: Expired,
    title: '提醒'
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const whiteList = ['/'] // no redirect whitelist
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  let hasToken = getToken()
  if (!hasToken) {
    /* has no token*/
    if (whiteList.indexOf(to.path)===-1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
  }
  if (to.path === '/login') {
    // if is logged in, redirect to the home page
    next({path: '/'});
  } else if (['/404', '/403', '/401', '/502'].includes(window.location.pathname)) {
    next();
  } else {
    next()
  }
});
export default router;
