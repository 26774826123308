import axios from 'axios'
import moment from 'moment';
import {getToken, removeToken} from '@/utils/auth'
import store from '@/store'
import router from "@/router";
let date = new Date();
let timezone = -(date.getTimezoneOffset()) / 60;
// create an Axios instance
const Axios = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
});

Axios.interceptors.request.use(config => {
    console.log(localStorage.getItem('Token'))
    if (getToken()) {
        config.headers['Authorization'] = getToken()
    }
    config.headers['Request-Date'] = `${moment(date).format('YYYY-MM-DD')} GMT${timezone >= 0 ? '+' + timezone : timezone}|${moment().utcOffset(-(date.getTimezoneOffset())).format('HH:mm:ss')}`;
    config.headers['User-Type'] = 'consultants';
    return config
}, error => {
    return Promise.reject(error)
});

Axios.interceptors.response.use(response => {
    return response
}, error => Promise.resolve(error.response));

function checkStatus(response) {
    if (!response) return;
    let status = response.status;// 返回的状态码;
    //如果返回401,则退出登陆
    if (status === 401) {
        removeToken();
        window.location.href='/login'
        return;
    }

    let res = response.data;
    if (res.code === 0) {
        return response.data
    }
    return response.data
}

function checkCode(res) {
    return res
}

const service = ({...data}) => {
    let method = data.hasOwnProperty('method') ? data.method : 'post';//如果传过来的有method则使用默认method，如果没有则使用POST。
    return request[method](data)

};
const request = {
    async post({url, data, showMessage = false}) {
        return await Axios({
            method: 'post',
            url,
            data: data,
            showMessage: showMessage,
            timeout: 30000,
        }).then(checkStatus).then(checkCode)
    },
    get({url, data, params, showMessage = false}) {
        return Axios({
            method: 'get',
            url,
            params,
            data,
            showMessage: showMessage,
            timeout: 30000,
            // headers: {
            //   'X-Requested-With': 'XMLHttpRequest'
            // }
        }).then(checkStatus).then(checkCode)
    },
    upload({url, data, showMessage = true}) {
        return Axios({
            method: 'post',
            url,
            data: data,
            showMessage: showMessage,
            timeout: 30000,
        }).then(checkStatus).then(checkCode)
    },
};
export default service
