import Mock from 'mockjs';

let List = [];
let count = 20;
let total = 1000;
const getInfo = (Id = null, ship = 0, linkId = 0) => {
  return Mock.mock({
    Id: Id || '@increment',
    Creation: Mock.mock('@datetime()'),
    LastModified: Mock.mock('@datetime()'),
    RecordState: '@integer(1, 3)',
    UserName: '@string(7, 10)',
    Password: /\w\W\s\S\d\D/,
    FirstName: '@first',
    LastName: '@last',
    Mark: '@last',
    JoinDate: () => {
      return new Date().getTime()
    },
    SigningDate: () => {
      return new Date().getTime()
    },
    Email: '@email',
    Cell: '+86 13925569941',
    Status: 4,
    SalesId:1,
    TeamId:1,
    PIC: 1,
    Gender: '@integer(1, 3)',
    DOB: () => {
      return new Date().getTime()
    },
    COB: 'hubei',
    EyeColor: 'blue',
    Height: 180,
    FormerName: 'laomeng',
    Nationality: 'china',
    Identity: '11110001110001111000011110',
    IdentityExp: () => {
      return new Date().getTime()
    },
    CanadaVisaIssue: () => {
      return new Date().getTime()
    },
    CanadaVisaExp: () => {
      return new Date().getTime()
    },
    USAVisaIssue: () => {
      return new Date().getTime()
    },
    USAVisaExp: () => {
      return new Date().getTime()
    },
    FirstVisitCity: 'hubei',
    FirstVisitDate: () => {
      return new Date().getTime()
    },
    LastVisitCity: 'hubei',
    LastVisitDate: () => {
      return new Date().getTime()
    },
    Marriage: 1,
    PartnerName: 'laomeng',
    MarriageStart: () => {
      return new Date().getTime()
    },
    LastPartnerName: 'laomeng',
    LastMaritalStart: () => {
      return new Date().getTime()
    },
    LastMaritalEnd: null,
    Passport: '10011011',
    PassportIssueCountry: 'china',
    PassportIssue: () => {
      return new Date().getTime()
    },
    PassportExp: () => {
      return new Date().getTime()
    },
    VisaExp: () => {
      return new Date().getTime()
    },
    PRExp: () => {
      return new Date().getTime()
    },
    WPExp: () => {
      return new Date().getTime()
    },
    SPExp: () => {
      return new Date().getTime()
    },
    LivingCountry: 'china',
    OtherNationality: 'USA',
    Address: '@address',
    LinkId: linkId,
    Relationship: ship,
    IsCompanion: 1,
    Department: 'CEO',
    DeathDate: null,
    DeathCity: null,
    Children: null,
    AdminLock: 1,
    SelfLock: 0,
    Background: JSON.stringify([
      {Id: 1, IsTrue: 0},
      {Id: 2, IsTrue: 1},
      {Id: 3, IsTrue: 1},
      {Id: 4, IsTrue: 1},
      {Id: 5, IsTrue: 0},
      {Id: 6, IsTrue: 0},
      {Id: 7, IsTrue: 1},
      {Id: 8, IsTrue: 1},
      {Id: 9, IsTrue: 0},
      {Id: 10, IsTrue: 0},
    ]),
    RequestUnlock:0,
    RequestUnlockReason:null,
  })
};
for (let i = 0; i < count; i++) {

  let item = getInfo(i + 1);
  item['Children'] = [getInfo(Mock.mock('@increment(100)'), '@integer(1, 4)', '@integer(1, 4)')];
  List.push(item)
}
export default [
  {
    url: '/member/index',
    type: 'post',
    response: config => {
      if (config.body) {
        let {begin, count, filters} = config.body;
        let list = [];
        for (let i = 0; i < 4; i++) {
          let item = getInfo(100 + i + 1, '@integer(1, 4)');
          item.LinkId = 1;
          list.push(item);
        }
        return {
          code: 0,
          data: {
            list: filters ? list : List,
            params: {
              begin: begin,
              count,
              total: total
            }
          }
        };
      } else {
        return {
          code: 0,
          data: {
            list: List
          }
        };
      }
    }
  },
  {
    url: '/member/view',
    type: 'post',
    response: config => {
      let {Id} = config.body;
      let info = getInfo(Id);
      info['Children'] = [];
      info['Children'][0] = getInfo(100 + 1, '@integer(1, 4)', 1);
      info['Children'][1] = getInfo(100 + 2, '@integer(1, 4)', 2);
      info['Children'][2] = getInfo(100 + 3, '@integer(1, 4)', 3);
      info['Children'][3] = getInfo(100 + 4, '@integer(1, 4)', 4);

      return {
        code: 0,
        data: info,
        message: '查询成功'
      }
    }
  },
  {
    url: '/member/info',
    type: 'post',
    response: config => {
      let info = getInfo(1);
      info['Children'] = [];
      info['Children'][0] = getInfo(100 + 1, '@integer(1, 4)', 1);
      info['Children'][1] = getInfo(100 + 2, '@integer(1, 4)', 2);
      info['Children'][2] = getInfo(100 + 3, '@integer(1, 4)', 3);
      info['Children'][3] = getInfo(100 + 4, '@integer(1, 4)', 4);
      return {
        code: 0,
        data: info,
        message: '查询成功'
      }
    }
  },
  {
    url: '/member/update',
    type: 'post',
    response: config => {
      console.log(config.body);
      return {
        code: 0,
        data: null,
        message: '更新成功'
      }
    }
  },
  {
    url: '/member/updateself',
    type: 'post',
    response: config => {
      console.log(config.body);
      return {
        code: 0,
        data: null,
        message: '更新成功'
      }
    }
  },
  {
    url: '/member/create',
    type: 'post',
    response: config => {
      console.log(config.body);
      return {
        code: 0,
        data: null,
        message: '新增成功'
      }
    }
  },
  {
    url: '/member/destroy',
    type: 'post',
    response: config => {
      return {
        code: 0,
        data: null,
        message: '删除成功'
      }
    }
  },
]
