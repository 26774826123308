import {login, info} from '@/api/user'
import {
    getToken,
    setToken,
    removeToken,
    setTokenExpires,
    setTokenTime,
    setUserInfo,
    getUserInfo,
    getAvatar,
    getUserId, getPort,
} from '@/utils/auth'
import {Message} from "element-ui";

const state = {
    token: getToken(),
    name: '',
    avatar: getAvatar(),
    userId: getUserId(),
    userInfo: getUserInfo(),
    introduction: '',
    roles: [],
    permissions: null,
    token_expires: null
};

const mutations = {
    SET_TOKEN: (state, token) => {
        console.log(token)
        state.token = token
    },
    SET_TOKEN_EXPIRES: (state, token_expires) => {
        state.token_expires = token_expires;
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
        state.permissions = permissions
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_USER_ID: (state, userId) => {
        state.userId = userId
    },
};

const actions = {
    async login({commit, dispatch}, userInfo) {
        const {username, password} = userInfo;
        let {code, data, message} = await login({username: username.trim(), password: password});
        if (code !== 0 || !data) {
            Message.error(message)
            return null;
        }
        commit('SET_TOKEN', data.token);
        commit('SET_TOKEN_EXPIRES', data.token_expires);
        commit('SET_USER_ID', data.Id);
        setToken(data.token);
        setTokenExpires(data.token_expires);
        let time = +new Date();
        setTokenTime(time);
        return await dispatch('getInfo', data.Id);
    },

    // get user info
    async getInfo({commit, dispatch}, userId) {
        let {code, data} = await info({Id: userId});
        if (code === 0) {
            commit('SET_NAME', data.UserName);
            commit('SET_AVATAR', data.Avatar);
            commit('SET_INTRODUCTION', '');
            commit('SET_USER_INFO', data);
            setUserInfo(data);
            return data;
        } else {
            dispatch('resetToken')
            return null;
        }
    },

    // get user roles
    async getRoles({commit}) {
        return new Promise(resolve => {
            let port = getPort();
            commit('SET_ROLES', [port]);
            resolve([port])
        });
    },
    // user logout
    logout({commit}) {
        setUserInfo({})
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        commit('SET_PERMISSIONS', null)
        removeToken();
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            setUserInfo({})
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', null)
            removeToken()
            resolve()
        })
    },
    //refresh token
    //刷新token
    refresh({commit}) {
        let BASE_API = process.env.VUE_APP_BASE_API;
        let request = new XMLHttpRequest();
        return new Promise((resolve, reject) => {
            request.onreadystatechange = function () {
                if (request.readyState !== 4) return;
                if (request.status >= 200 && request.status < 300) {
                    if (request.responseText && JSON.parse(request.responseText)) {
                        let res = JSON.parse(request.responseText);
                        if (+res.code === 0) {
                            console.log(res.data)
                            commit('SET_TOKEN', res.data.token);
                            commit('SET_TOKEN_EXPIRES', res.data.token_expires);
                            setToken(res.data.token);
                            setTokenExpires(res.data.token_expires);
                            let time = +new Date();
                            setTokenTime(time);
                        }
                        resolve(res);
                    }
                } else {
                    reject({
                        status: request.status,
                        statusText: request.statusText
                    });
                }
            };
            request.open('POST', `${BASE_API}refresh`, true);
            request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            request.setRequestHeader('Authorization', getToken());
            request.send();
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
