<template>
  <div class="home">
    <head-component></head-component>
    <section class="content">
      <div class="btns">
        <el-button type='primary' @click="onSetColor" :loading="loading">AIMS同步插件</el-button>
      </div>
      <div class="tips">
        <h3>AIMS进度表格同步规则</h3>
        <ol>
          <li>保持AIMS同步插件打开状态，请勿关闭</li>
          <li>粉色格为不可修改，黄色格为可以修改</li>
          <li>删除和添加行操作需要在AIMS系统中进行</li>
          <li>修改实时生效</li>
        </ol>
      </div>
    </section>
  </div>
</template>

<script>
import {intApi} from "@/api";
import HeadComponent from "@/components/head";

function generateAlphabet() {
  let str = [];
  for (let i = 65; i < 91; i++) {
    str.push(String.fromCharCode(i));
  }
  return str;
}

const alphabet = generateAlphabet();
const START = 2;//数据起始行
const fieldsMap = {
  Member: {
    LastName: 'D',
    FirstName: 'E'
  },
  MemberProject: {
    Notes: 'H',
    ApplicationProgress: 'I',
    DateSubmitted: 'J',
    ApplicationNo: 'K',
    IRCCReceiptNo: 'L',
    MailingTrackingNo: 'M',
    MedicalExam: 'N',
    Results: 'O'
  },

}
const getValue = (key, row) => {
  let form = fieldsMap[key];
  let data = {};
  Object.keys(form).forEach(r => {
    data[r] = row[alphabet.indexOf(form[r])]
  })
  return data;
}
const clear = (map) => {
  window.Excel.run(function (context) {
    let sheet = context.workbook.worksheets.getActiveWorksheet();
    let range = sheet.getRange(map);
    range.clear();
    return context.sync();
  });
}

function isLetter(str) {
  for (let i in str) {
    let asc = str.charCodeAt(i);
    if ((asc >= 65 && asc <= 90 || asc >= 97 && asc <= 122)) {
      return true;
    }
  }
  return false;
}

const generateSingle = async (address) => {
  let y = +address.replace(/[^\d]/g, '');//行；
  if (y < START) return;
  let data = null
  await window.Excel.run(async context => {
    let currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
    let expensesTable = currentWorksheet.getRange(`A${y}:Z${y}`);
    expensesTable.load('text')
    await context.sync();
    let rows = expensesTable.text;
    let row = rows[0];
    let IDS = row[0] && row[0].split('|') || null;
    let mid, pid
    if (IDS) {
      mid = +IDS[0]
      pid = +IDS[1]
    }
    if (!mid || !pid) {
      clear(`A${y}:Z${y}`);
      let dialog;
      let processMessage = () => {
        dialog && dialog.close();
      }
      Office.context.ui.displayDialogAsync(`${window.location.origin}/prevent`, {
        width: 50,
        height: 50,
        displayInIframe: true
      }, asyncResult => {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      });
      return;
    }
    let member = getValue('Member', row)
    let memberProject = getValue('MemberProject', row)
    data = [{
      Member: {...member, MemberId: mid},
      MemberProject: {...memberProject, MemberProjectId: pid},
    }]
  });
  return data;
}

const generateList = async (address) => {
  let s = +address.split(':')[0].replace(/[^\d]/g, '');//行；
  let e = +address.split(':')[1].replace(/[^\d]/g, '');//行；
  if (s < START) return;
  let data = null
  await window.Excel.run(async context => {
    let currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
    let expensesTable = currentWorksheet.getRange(`A${s}:Z${e}`);
    expensesTable.load('text')
    await context.sync();
    let rows = expensesTable.text;
    let list = rows.map(row => {
      let IDS = row[0] && row[0].split('|') || null;
      let mid, pid
      if (IDS) {
        mid = +IDS[0]
        pid = +IDS[1]
      }
      if (!mid || !pid) {
        return null;
      }
      let member = getValue('Member', row)
      let memberProject = getValue('MemberProject', row)
      return {
        Member: {...member, MemberId: mid},
        MemberProject: {...memberProject, MemberProjectId: pid},
      }
    })

    data = list.filter(i => i);
    if (data.length !== list.length) {
      clear(`A${s}:Z${e}`);
      let dialog;
      let processMessage = () => {
        dialog && dialog.close();
      }
      Office.context.ui.displayDialogAsync(`${window.location.origin}/prevent`, {
        width: 50,
        height: 50,
        displayInIframe: true
      }, asyncResult => {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      });
      return;
    }
  });
  return data;
}
export default {
  name: "Home",
  components: {HeadComponent},
  data() {
    return {
      loading: false,
      isDown: false,
      isAll: false,
      TeamName: null
    }
  },
  mounted() {
    let _this = this;
    window.Excel.run(async context => {
      let currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
      currentWorksheet.onChanged.add(_this.onWorkbookAutoSaveChange)
      currentWorksheet.onSingleClicked.add(_this.onSingleClicked);
      currentWorksheet.onSelectionChanged.add(_this.onSelectionChanged);
      let range = currentWorksheet.getRange('AA1');
      range.load("values");
      await context.sync().then(_ => {
        _this.TeamName = range.values[0][0]
        _this.onSetColor();
      });

    })
  },
  methods: {
    async onSetColor() {
      this.loading = true;
      let {code, data} = await intApi('memberproject').list({TeamName: this.TeamName});
      this.loading = false;
      let list = [];
      if (code !== 0 || !data || !data.members || data.members && !data.members.length) {
        return;
      }
      let members = data.members;
      members.forEach(member => {
        if (member.MemberProjects.length) {
          member.MemberProjects.forEach(project => {
            list.push({
              "ID": `${member.MemberId}|${project.MemberProjectId}`,
              "Client Last Name": member.LastName,
              "Client First Name": member.FirstName,
              "Date Assigned": project.Creation,
              "Program": project.ProjectName,
              "Office": member.Team && member.Team.TeamName,
              "Case Processor": member.PICName || null,
              "Notes": project.Notes || null,
              "Application No.": project.ApplicationNo || null,
              "Application Progress": project.ApplicationProgress || null,
              "Date Submitted": project.DateSubmitted || null,
              "IRCC Receipt No.": project.IRCCReceiptNo || null,
              "Mailing Tracking No.": project.MailingTrackingNo || null,
              "Medical Exam": project.MedicalExam || null,
              "Results": project.Results || null,
            })
          })
        }
      })
      let headers = ['ID', 'Date Assigned', 'Program', 'Client Last Name', 'Client First Name', 'Office', 'Case Processor', 'Notes', 'Application Progress', 'Date Submitted', 'Application No.', 'IRCC Receipt No.', 'Mailing Tracking No.', 'Medical Exam', 'Results'];
      let rows = [];
      list.forEach(item => {
        let row = headers.map(h => {
          if (h === 'Date Assigned') {
            return item[h] ? new Date(item[h]).toISOString().split('T')[0] : ''
          } else {
            return item[h]
          }
        });
        rows.push(row);
      })
      let _this = this;
      window.Excel.run(async context => {
        let currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
        let clearRange = currentWorksheet.getRange(`A${START}:${alphabet[headers.length - 1]}5000`);
        clearRange.clear();
        await context.sync();
        if(list.length){
          let range = currentWorksheet.getRange(`A${START}:${alphabet[headers.length - 1]}${START + rows.length - 1}`);
          range.values = rows;
          await context.sync();
        }
        const teamRange = currentWorksheet.getRange('AA:AA');
        teamRange.columnHidden = true
        await context.sync();
        const firstRange = currentWorksheet.getRange("A:A");
        firstRange.columnHidden = true
        await context.sync();
        currentWorksheet.freezePanes.unfreeze()
        await context.sync();
        _this.isDown = true;
      });
    },
    onSingleClicked(event) {
      let {address} = event;
      if (!address) return;
      let x = address.split('')[0];
      // let y = +address.replace(/[^\d]/g, '')
      // let maps = {
      //   E: {
      //     start: 2,
      //     type: 'team'
      //   }
      // };
      // if (!maps.hasOwnProperty(x.toUpperCase())) return;
      // let row = maps[x.toUpperCase()];
      // if (row.start > +y) return;
      let maps = ['A', 'B', 'C', 'F', 'G'];
      if (!maps.includes(x)) {
        return;
      }
      return this.onEditor();
      // let dialog;
      // let processMessage = (arg) => {
      //   let message = JSON.parse(arg.message);
      //   let {type, value} = message;
      //   if (type === 'save') return this.input(address, value, dialog);
      //   dialog && dialog.close();
      // }
      // Office.context.ui.displayDialogAsync(`${window.location.origin}/dialog/${row.type}`, {
      //   width: 50,
      //   height: 50,
      //   displayInIframe: true
      // }, asyncResult => {
      //   dialog = asyncResult.value;
      //   dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      // });
    },
    input(address, value, dialog) {
      window.Excel.run(async context => {
        let currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
        let range = currentWorksheet.getRange(address);
        range.values = value;
        context.sync()
        dialog.close();
      });
    },
    onDeleted() {
      this.onSetColor();
      let dialog;
      let processMessage = () => {
        console.log(11111)
        dialog && dialog.close();
      }
      Office.context.ui.displayDialogAsync(`${window.location.origin}/prevent`, {
        width: 30,
        height: 20,
        displayInIframe: true
      }, asyncResult => {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      });
    },
    onEditor() {
      this.onSetColor();
      let dialog;
      let processMessage = () => {
        dialog && dialog.close();
      }
      Office.context.ui.displayDialogAsync(`${window.location.origin}/editor`, {
        width: 30,
        height: 20,
        displayInIframe: true
      }, asyncResult => {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      });
    },
    async onWorkbookAutoSaveChange(args) {
      let {address, details, source, changeType, triggerSource} = args;
      // console.log(address)
      if (triggerSource === 'ThisLocalAddin') {
        return;
      }
      if (['RowDeleted', 'RangeEdited', 'RowInserted'].includes(changeType) && !isLetter(address)) {
        return this.onDeleted();
      }
      if (source !== "Local") return;
      let data = []
      //如果address是单个坐标，且details有值。则属于单个输入框编辑
      if (address.indexOf(':') < 0 && details) {
        if (!details) return;
        data = await generateSingle(address)
      }
      if (address.indexOf(':') > -1 && !this.isAll) {
        data = await generateList(address)
        this.isAll = true;
      } else {
        this.isAll = false;
      }
      if (!data || !data.length) return;
      await intApi('memberproject').update(data);
    },
    onSelectionChanged(event) {
      let {address} = event;
      Excel.run(function (context) {
        let activeSheet = context.workbook.worksheets.getActiveWorksheet();
        activeSheet.load("protection/protected");
        return context.sync().then(function () {
          if (!address.replace(/[^0-9]/ig, "")) {
            activeSheet.protection.protect()
          } else {
            activeSheet.protection.unprotect();
          }
        })
      });
    },
    onActivated(event) {
      console.log(event)
    }
  }
};
</script>
<style lang="less">
.home {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content {
    padding: 1rem;
    flex: auto;
    display: flex;
    flex-direction: column;

    .btns {
      display: flex;
      justify-content: center;
    }

    .tips {
      padding: 10px;
      text-align: center;
      color: #000;
      margin-top: 4rem;
      border: 1px solid #efefef;
      border-radius: 4px;

      ol {
        padding-left: 24px;
      }

      li {
        font-size: 14px;
        color: #000;
        text-align: left;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
