import interfaceBuilder from './common'

const modelInterface = (module = null) => {
    if (!module) return;
    //从网址中解析出当前板块
    let interfaces = {};
    let context = require.context('.', true, /.*\.js/);
    try {
        interfaces = context(`./${module}.js`);

    } catch (e) {
        console.log(e.message);
    }
    return interfaces;
};
const commonInterface = (module = null) => {
    return interfaceBuilder(module);
};
//合并模块接口和默认接口
export const intApi = (module) => {
    return {...commonInterface(module), ...modelInterface(module)}
};
