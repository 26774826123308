<template>
  <div class="dialog">
    <div class="title">选择{{ title }}</div>
    <div class="list">
      <div class="item" v-for="(item,index) in list" :key="index" @click="active=index"
           :class="{'active':active===index}">
        {{ item }}
      </div>
    </div>
    <div class="footer">
      <el-button @click="close()" :loading="loading">取消</el-button>
      <el-button @click="save()" type="primary" :loading="loading">确定</el-button>
    </div>
  </div>
</template>

<script>
import {intApi} from "@/api";

const types = {
  team: {
    title: '办公室',
    source: async () => {
      let {code, data} = await intApi('team').index({begin: -1, count: -1})
      if (code !== 0 || !data || !data.list) return null;
      return data.list.map(i => i.TeamName);
    }
  }
}
export default {
  name: "Dialog",
  props: {
    type: {
      type: String,
      default: 'team'
    }
  },
  data() {
    return {
      list: null,
      active: null,
      loading:false,
    }
  },
  computed: {
    title() {
      if (!this.type || !types.hasOwnProperty(this.type)) return;
      return types[this.type].title;
    }
  },
  async created() {
    if (!this.type || !types.hasOwnProperty(this.type)) return;
    let list = await types[this.type].source();
    this.list = list;
  },
  methods:{
    close() {
      this.loading=true;
      let messageObject = {type: "close"};
      let jsonMessage = JSON.stringify(messageObject);
      Office.context.ui.messageParent(jsonMessage);
    },
    save() {
      this.loading=true;
      if(this.active===null)return this.$message.error('您未选中任何值!')
      let messageObject = {type: "save", value: this.list[this.active]};
      let jsonMessage = JSON.stringify(messageObject);
      Office.context.ui.messageParent(jsonMessage);
    }
  }
}
</script>

<style scoped lang="less">
.dialog {
  width: 100vw;
  height: 100%;
  padding: 3rem 1rem 4rem 1rem;
  box-sizing: border-box;

  .title {
    padding: 10px 1rem;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #efefef;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;

    .item {
      flex: none;
      width: ~"calc(50% - 10px)";
      margin: 5px;
      box-sizing: border-box;
      padding: 15px 20px;
      background: #fff;
      box-shadow: 3px 6px 10px rgba(124, 124, 124, .124);
      color: #444;
      cursor: pointer;
      border-radius: 5px;
      transition: 0.3s ease-in-out;

      &:hover, &.active {
        background: #409EFF;
        color: #fff;
      }
    }
  }
  .footer{
    position: fixed;bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 1rem;
    background: #fff;
    text-align: right;
  }
}
</style>
