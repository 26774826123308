import Vue from "vue";
import App from "./App.vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'

Vue.use(ElementUI);
Vue.config.productionTip = false;
import {mockXHR} from '../mock'

if (process.env.NODE_ENV === 'development') {
  // mockXHR() //
}

window.Office.onReady(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");

});
