<template>
  <div class="login">
    <head-component title="登录"></head-component>
    <section class="login-content">
      <el-form :model="form" :rules="rules" ref="loginForm" label-width="80px" label-position="top" class="login-form">
        <el-form-item prop="username" label="用户名">
          <el-input v-model="form.username" placeholder="请输入用户名">
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input v-model="form.password" placeholder="请输入密码" type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit('ruleForm')" style="width: 100%;" :loading="loading">登录</el-button>
        </el-form-item>
      </el-form>

    </section>
  </div>
</template>

<script>
import HeadComponent from "@/components/head";
import {getToken} from "@/utils/auth";

const validateUsername = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入用户名'))
  } else {
    callback()
  }
};
const validatePassword = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入密码'));
  } else {
    callback()
  }
};
export default {
  name: "Login",
  components: {HeadComponent},
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      loading: false,
      rules: {
        username: [{required: true, trigger: 'blur', validator: validateUsername}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}],
      },
      eventResult: null
    }
  },
  computed: {
    redirect() {
      const query = this.$route.query
      if (query) {
        return query.redirect || '/'
      }
    }
  },
  mounted() {
    this.freeze();
  },
  methods: {
    freeze() {
      const _this = this;
      window.Excel.run(async context => {
        let currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
        _this.eventResult = currentWorksheet.onSingleClicked.add(_this.onSingleClicked);
        await context.sync();
      })
    },
    onSingleClicked() {
      let dialog;
      let processMessage = (arg) => {
        dialog && dialog.close();
      }
      Office.context.ui.displayDialogAsync(`${window.location.origin}/expired`, {
        width: 30,
        height: 30,
        displayInIframe: true
      }, asyncResult => {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      });
    },
    submit() {
      this.loading = true;
      this.$refs.loginForm.validate(valid => {
        if (!valid) return this.$message({message: '请输入用户名和密码', type: 'error'});
        this.login()
      })
    },
    async login() {
      let data = await this.$store.dispatch('user/login', this.form);
      this.loading = false;
      const _this = this;
      if (!data) return;
      await window.Excel.run(_this.eventResult.context, async (context) => {
        _this.eventResult.remove();
        await context.sync();
        _this.eventResult = null;
        console.log("Event handler successfully removed.");
      });
      return this.$router.push(this.redirect);
    }
  }
}
</script>

<style scoped lang="less">
.login {
  height: 100vh;
  background: #ffff;

  .login-content {
    padding: 2rem;

    .login-form {
      background: #fff;
      padding: 1rem;
      border-radius: 10px;
      border: 1px solid #efefef;
    }
  }
}
</style>
