import request from './request'

const interfaceBuilder = (module) => {
  if (!module) {
    return;
  }
  return {
    //分页列表
    index: async (data = null) => {
      let list = await request({
        url: `/${module}/index`,
        method: 'post',
        data
      });
      return list;
    },
    //全部列表
    list: async (data = null) => {
      console.log(data)
      let list = await request({
        url: `/${module}/list`,
        method: 'post',
        data
      });
      return list;
    },
    //编辑
    edit: async (data = null) => {
      let info = await request({
        url: `/${module}/view`,
        method: 'post',
        data
      });
      return info;
    },
    //查看
    view: async (data) => {
      let info = await request({
        url: `/${module}/view`,
        method: 'post',
        data
      });
      return info;
    },
    //新增
    create: async (data) => {
      return await request({
        url: `/${module}/create`,
        method: 'post',
        data
      })
    },
    //更新
    update: async (data) => {
      return await request({
        url: `/${module}/update`,
        method: 'post',
        data
      })
    },
    //删除
    destroy: async (data) => {
      return await request({
        url: `/${module}/destroy`,
        method: 'post',
        data
      })
    },
    //上传图片
    upload: async (data) => {
      return await request({
        url: `/uploadfile/create`,
        method: 'upload',
        data
      })
    },
  }
};

export default interfaceBuilder
