const TokenKey = 'Token';
const TokenExpiresKey = 'Token-expires'
const TokenTimeKey = 'Token-time'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function checkTokenExpires() {
  let tokenTime = +localStorage.getItem(TokenTimeKey);//token起始token的时间戳
  let tokenExpires =localStorage.getItem(TokenExpiresKey);//token过期时长
  let tokenOutTime = tokenTime + (tokenExpires * 1000);
  let time = +new Date().getTime();//当前时间戳
  let refreshTime = tokenTime + (tokenExpires * 1000 * 0.8);//获取截止要刷新token的时间戳。
  let needRefresh = time > refreshTime && time < tokenOutTime;//当前时间小于token刷新时间但是大于token刷新时间的80%。则返回需要刷新的参数；
  if (time >= tokenOutTime) {
    return 3;
  }
  return needRefresh ? 2 : 1
}

export function setToken(token) {
  return localStorage.setItem(TokenKey,token)
}

export function setTokenExpires(expires) {

  return localStorage.setItem(TokenExpiresKey, expires)
}

export function setTokenTime(tokenTime) {

  return localStorage.setItem(TokenTimeKey, tokenTime)
}

export function removeToken() {
  window.localStorage.removeItem('userInfo');
  localStorage.removeItem(TokenTimeKey)
  localStorage.removeItem(TokenExpiresKey)
  localStorage.removeItem(TokenKey)
  return window.location.reload();
}

//获取语言
export function getLang() {
  return window.localStorage.getItem('lang') || null
}

//设置用户数据到缓存中。
export function setUserInfo(info) {
  return window.localStorage.setItem('userInfo', JSON.stringify(info))
}

//获取用户数据
export function getUserInfo(keys = null) {
  let info = window.localStorage.getItem('userInfo');
  if (!info) {
    return null
  }
  info = JSON.parse(info);
  if (keys && Array.isArray(keys)) {
    let data = {};
    keys.forEach(key => {
      data[key] = info[key]
    });
    return data;
  }
  return info
}

//获取用户id
export function getUserId() {
  let info = getUserInfo();
  if (!info) {
    return null;
  }
  return info.Id;
}

//获取用户头像
export function getAvatar() {
  let info = getUserInfo();
  if (!info) {
    return -1;
  }
  return info.Avatar;
}

//判断传过来的id是不是登陆用户
export function isMe(id) {
  let loginUserId = getUserId();
  if (!loginUserId || !id) {
    return false;
  }
  return +loginUserId === +id;
}

//获取当前访问者所属端
export function getPort() {
  return 'consultants'
}
