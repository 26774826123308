<template>
  <div class="prevent">
    <div class="content">
      <p>禁止在excel中增加或删除客户数据，如需删除请在AIMS中操作。删除或增加的数据行将恢复，谢谢。</p>
    </div>
    <div class="footer">
<!--      <el-button @click="submit()" type="primary" :loading="loading">确定</el-button>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Prevent",
  data() {
    return {
      loading: false
    }
  },
  methods: {
    submit() {
      this.loading = true;
      let messageObject = {};
      let jsonMessage = JSON.stringify(messageObject);
      Office.context.ui.messageParent(jsonMessage);
    }
  }
}
</script>

<style scoped lang="less">
.prevent {
  .content {
    padding: 1rem;
    //background: #fff8f2;
    color: #f9ae3a;
    //border-radius: 10px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 1rem;
    background: #fff;
    text-align: right;
  }
}
</style>
